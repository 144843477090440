import React from 'react';

import { fg } from '@confluence/feature-gating';

import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { LoadableLazy } from '@confluence/loadable';

const RovoVideoModalLoadable = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-RovoVideoModal" */ '../src/index')).RovoVideoModal,
});

export const RovoVideoModal = () => {
	return fg('confluence_rovo_video_player_gate') ? (
		<TransparentErrorBoundary attribution={Attribution.GROWTH_LUNA}>
			<RovoVideoModalLoadable />
		</TransparentErrorBoundary>
	) : null;
};
