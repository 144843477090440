import React from 'react';

import { LoadableLazy } from '@confluence/loadable';

import { useQuickstartEligible } from './useQuickstartEligible';

const OnboardingQuickstartLoadable = LoadableLazy({
	loader: async () => {
		const { OnboardingQuickstartComponent } = await import(
			/* webpackChunkName: "loadable-OnboardingQuickstartComponent" */ './OnboardingQuickstartComponent'
		);
		return OnboardingQuickstartComponent;
	},
});

export const OnboardingQuickstart = ({ children }: { children?: React.ReactNode }) => {
	const { isQuickstartEligible } = useQuickstartEligible();

	return isQuickstartEligible() ? (
		<OnboardingQuickstartLoadable>{children}</OnboardingQuickstartLoadable>
	) : null;
};
