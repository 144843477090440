import React, { memo } from 'react';

import { useRouteActions } from '@confluence/route-manager';
import { isErrorMarkedAsHandled } from '@confluence/graphql-error-processor';
import { ErrorDisplay } from '@confluence/error-boundary';
import { GLOBAL_PAGE_FORGE } from '@confluence/named-routes';

import { FORGE_MODULE_GLOBAL_PAGE } from '../../ForgeModuleType';
import { LazyForgeUIExtensions } from '../../lazy';
import { getAppId } from '../../utils';

import { ForgeKeyboardShortcut } from './ForgeKeyboardShortcut';

export const ForgeGlobalKeyboardShortcuts = memo(() => {
	const { push } = useRouteActions();

	return (
		<LazyForgeUIExtensions
			moduleType={FORGE_MODULE_GLOBAL_PAGE}
			render={(forgeModules, loading, error) => {
				if (loading) return null;

				if (error) {
					if (!isErrorMarkedAsHandled(error)) {
						return <ErrorDisplay error={error} />;
					}
					return null;
				}

				return forgeModules.map((module) => (
					<ForgeKeyboardShortcut
						key={module.id}
						module={module}
						action={() =>
							push(
								GLOBAL_PAGE_FORGE.toUrl({
									appId: getAppId(module),
									envId: module.environmentId,
									forgeManifestRoute: module.properties.route,
								}),
							)
						}
					/>
				));
			}}
		/>
	);
});
