import type { MutableRefObject } from 'react';
import { createStore, createHook } from 'react-sweet-state';

type State = {
	showCreateSpaceDrawer: boolean;
	shouldRenderDrawer: boolean;
	createSpaceButtonRef: MutableRefObject<HTMLElement | null>;
};

const initialState: State = {
	showCreateSpaceDrawer: false,
	shouldRenderDrawer: false,
	createSpaceButtonRef: {
		current: null,
	} as MutableRefObject<HTMLElement | null>,
};
export const actions = {
	openDrawer:
		(value: MutableRefObject<HTMLElement | null>) =>
		({ setState }) => {
			setState({
				showCreateSpaceDrawer: true,
				shouldRenderDrawer: true,
				createSpaceButtonRef: value,
			});
		},
	closeDrawer:
		() =>
		({ setState }) => {
			setState({ showCreateSpaceDrawer: false });
		},
};

export const Store = createStore({
	initialState,
	actions,
	name: 'createSpaceStore',
});

export const useCreateSpaceStore = createHook(Store);

const getShouldRenderDrawer = (state: { shouldRenderDrawer: boolean }) => {
	return state.shouldRenderDrawer;
};

export const useShouldRenderDrawer = createHook(Store, {
	selector: getShouldRenderDrawer,
});
