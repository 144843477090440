import React from 'react';

import { StorageEnforcementFlags } from './flags/StorageEnforcementFlagsLazy';
import { GracePeriodModal } from './modals/GracePeriodModal/GracePeriodModalLazy';
import { BlockCreateModal } from './modals/BlockCreateModal/BlockCreateModalLazy';

export const StorageEnforcement = () => {
	return (
		<>
			<StorageEnforcementFlags />
			<GracePeriodModal />
			<BlockCreateModal />
		</>
	);
};
