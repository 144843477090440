import React, { type ReactElement } from 'react';

import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { LoadableBackground } from '@confluence/loadable';

const EndOfTrialModalLoadable = LoadableBackground({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-EndOfTrialModal" */ '../src/components/EndOfTrialModalWrapper'
			)
		).EndOfTrialModalWrapper,
});

export const LoadableEndOfTrialModal = ({ children }: { children: ReactElement }) => {
	return (
		<TransparentErrorBoundary attribution={Attribution.GROWTH_LUNA}>
			<EndOfTrialModalLoadable>{children}</EndOfTrialModalLoadable>
		</TransparentErrorBoundary>
	);
};
