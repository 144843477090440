import React from 'react';

import { LoadableLazy } from '@confluence/loadable';

import { useIsStorageEnforcementEligible } from '../../hooks/useIsStorageEnforcementEligible';

const BlockCreateModalLoadable = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-BlockCreateModalLoadable" */ './BlockCreateModal'))
			.BlockCreateModal,
});

export const BlockCreateModal = () => {
	const isEligibleUser = useIsStorageEnforcementEligible();
	return isEligibleUser ? <BlockCreateModalLoadable /> : null;
};
