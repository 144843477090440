import { LoadableLazy } from '@confluence/loadable';

import type { RestrictionsDialogMountPointProps } from './RestrictionsDialogMountPoint';
export { RestrictionsDialogTriggerWrapper } from './RestrictionsDialogTriggerWrapper';
export { RestrictionsDialogTrigger } from './RestrictionsDialogTrigger';
export { RestrictionsDialogMenuPropsProvider } from './RestrictionsDialogMenuPropsProvider';

export const LazyRestrictionsDialogMountPoint = LoadableLazy<RestrictionsDialogMountPointProps>({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-RestrictionsDialogMountPoint" */ './RestrictionsDialogMountPoint'
			)
		).RestrictionsDialogMountPoint,
});
