import type { FC } from 'react';
import React from 'react';

import { LoadableLazy } from '@confluence/loadable';

import { useShouldRenderDrawer } from './CreateSpaceStore';

const CreateSpaceDrawerLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CreateSpaceDrawer" */ './CreateSpaceDrawer'))
			.CreateSpaceDrawer,
});

export const ConditionalCreateSpaceDrawer: FC = () => {
	const [shouldRenderDrawer] = useShouldRenderDrawer();
	return shouldRenderDrawer ? <CreateSpaceDrawerLoader /> : null;
};
