import React from 'react';

import { LoadableLazy } from '@confluence/loadable';

import { useIsStorageEnforcementEligible } from './../hooks/useIsStorageEnforcementEligible';

const StorageEnforcementFlagsLoadable = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-StorageEnforcementFlagsLoadable" */ './../flags/StorageEnforcementFlags'
			)
		).StorageEnforcementFlags,
});

export const StorageEnforcementFlags = () => {
	const isEligibleUser = useIsStorageEnforcementEligible();
	return isEligibleUser ? <StorageEnforcementFlagsLoadable /> : null;
};
