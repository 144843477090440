import React from 'react';

import { LoadableLazy } from '@confluence/loadable';

import { useIsStorageEnforcementEligible } from '../../hooks/useIsStorageEnforcementEligible';

const GracePeriodModalLoadable = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-GracePeriodModalLoadable" */ './GracePeriodModal'))
			.GracePeriodModalErrorBoundary,
});

export const GracePeriodModal = () => {
	const isEligibleUser = useIsStorageEnforcementEligible();
	return isEligibleUser ? <GracePeriodModalLoadable /> : null;
};
